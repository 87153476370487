import { createStore } from 'vuex'
import cart from './cart'
import catalog from './catalog'
import orders from './orders'
import images from './images'
import prints from './prints'
import gallery from './gallery'

const store = createStore({
    modules: {
        cart: cart,
        catalog: catalog,
        orders: orders,
        images: images,
        prints: prints,
        gallery: gallery,
    }
})

export default store;