export default {
    namespaced: true,
    state: {
        enablePrints: true,
        enablePurchases: false,
        prices: {
            Art: {
                '8x8': {price: 5000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '8x10': {price: 6000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '12x12': {price: 10000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '11x14': {price: 17500, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '16x20': {price: 20000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '20x30': {price: 25000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'}
            },
            Tile: {
                '8x8': {price: 6000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '8x10': {price: 7000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '12x12': {price: 10000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '10x14': {price: 10000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '16x16': {price: 12000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
            },
            Metal: {
                '8x10': {price: 10000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '12x12': {price: 11500, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '16x20': {price: 25000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '20x20': {price: 50000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '10x14': {price: 11500, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '16x16': {price: 24000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '10x24': {price: 30000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '12x36': {price: 50000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '24x36': {price: 85000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '30x40': {price: 100000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
            },
            Canvas: {
                '8x10': {price: 16000, shipping: 1500, shipTime: 'Ships in 4 to 6 weeks'},
                '10x14': {price: 20000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '16x16': {price: 30000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '10x24': {price: 40000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '12x36': {price: 50000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '24x36': {price: 60000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '30x40': {price: 70000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
                '36x36': {price: 80000, shipping: 2500, shipTime: 'Ships in 4 to 6 weeks'},
            }
        },
        details: {
            artPrint: {
                title: 'Photo Art Prints (Paper)',
                descriptionTitle: 'Art Prints',
                description:
                    'Printed on recyclable signature cardstock paper featuring a luxurious matte finish and smooth surface with the sharpest photo quality and color accuracy.\n' +
                    'High Resolution and Sharp Images: Excellent color accuracy produced by the highest quality digital technology.\n' +
                    'Quality Cardstock: Smooth, matte-finish; expertly crafted for exceptional design and photo quality.\n' +
                    'Cardstock is FSC® certified, ensuring sustainable practices and responsibly forested.\n' +
                    'Printed in USA.\n' +
                    'Frames are not included, but our design service is available to help you choose a style that harmonizes with your interior.',
                priceTable: [
                    { size: '8x8', price: '$50' },
                    { size: '8x10', price: '$60' },
                    { size: '12x12', price: '$100' },
                    { size: '11x14', price: '$175' },
                    { size: '16x20', price: '$200' },
                    { size: '20x30', price: '$250' }
                ]
            },
            tile: {
                title: 'Metal Photo Tiles',
                descriptionTitle: 'Photo Tiles',
                description: 'Repositionable, lightweight metal photo tiles.\n' +
                    'Double-sided adhesive allows you to hang and rearrange with ease. No nails or holes required, and no sticky residue!\n' +
                    'High Quality ChromaLuxe®: Offers clean edges and long-lasting design.\n' +
                    'Vibrant and Sharp Image: Glossy finish makes color pop and is scratch, humidity, and fade resistant.\n' +
                    'No Nails or Holes: Lightweight tiles arrive ready to hang.\n' +
                    'Easy to Rearrange: Double sided adhesive leaves no sticky residue.\n' +
                    'Not intended for laminated (wallpaper) or textured surfaces.\n' +
                    '100% Recyclable: Made with recycled aluminum.\n' +
                    'Made in USA.',
                priceTable: [
                    { size: '8x8', price: '$60' },
                    { size: '8x10', price: '$70' },
                    { size: '12x12', price: '$100' },
                    { size: '10x14', price: '$100' },
                    { size: '16x16', price: '$120' },
                ]
            },
            metal: {
                title: 'Metal Wall Art Prints',
                descriptionTitle: 'Metal Wall Art',
                description: 'High-definition metal wall art for a clean, modern look. Metal prints are made of high-quality aluminum, with a white base coat that allows for vibrant colors to come through. Available in a variety of sizes.\n' +
                    'High Quality ChromaLuxe®: Offers clean edges and long-lasting design.\n' +
                    'Vibrant and Sharp Image: Glossy finish makes color pop and is scratch, humidity, and fade resistant.\n' +
                    'Finishing Options: Available in glossy or matte finish, 30x40 is only available in glossy.\n' +
                    'Ready to Hang: has a mounting block attached to back.\n' +
                    'Use caution when hanging, as the large sizes are heavy.\n' +
                    'Made in USA.',
                priceTable: [
                    { size: '8x10', price: '$100' },
                    { size: '12x12', price: '$115' },
                    { size: '16x20', price: '$250' },
                    { size: '20x20', price: '$500' },
                    { size: '10x14', price: '$115' },
                    { size: '16x16', price: '$240' },
                    { size: '10x24', price: '$300' },
                    { size: '12x36', price: '$500' },
                    { size: '24x36', price: '$850' },
                    { size: '30x40', price: '$1000' },
                ]
            },
            canvas: {
                title: 'Canvas',
                descriptionTitle: 'Canvas Prints',
                description: 'Available in a variety of sizes.\n' +
                    'Premium Poly-Cotton Canvas: Tested to the highest archival ratings for superior colorfastness for years to come.\n' +
                    'Vivid Long-Lasting Color: Giclee printing will not fade or yellow and is both scratch and humidity resistant.\n' +
                    'Gallery-Wrapped Edges: This premium technique offers a finished look from all angles.\n' +
                    'No Warping or Sagging: Impact resistant and professionally stretched across solid wood.\n' +
                    'Ready to Hang: Metal sawtooth hanger included.',
                priceTable: [
                    { size: '8x10', price: '$160' },
                    { size: '10x14', price: '$200' },
                    { size: '16x16', price: '$300' },
                    { size: '10x24', price: '$400' },
                    { size: '12x36', price: '$500' },
                    { size: '24x36', price: '$600' },
                    { size: '30x40', price: '$700' },
                    { size: '36x36', price: '$800' },
                ]
            }
        },
        categories: null,
        categoryData: [],

        formats: null,
    },
    getters: {
        enablePrints(state) {
            return state.enablePrints;
        },
        enablePurchases: (state) => state.enablePurchases,
        categories: (state) => state.categories,
        prices: (state) => state.prices,
        details: (state) => state.details,
        categoryData: (state) => state.categoryData,

        formats: (state) => state.formats,

    },
    mutations: {
        setEnablePrints(state, enabled) {
            state.enablePrints = enabled;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setFormats(state, formats) {
            state.formats = formats;
        }
    },
    actions: {
        async loadCategories(context) {
            if (context.getters.categories) {
                return context.getters.categories;
            }

            try {
                const catFile = `/images/prints/categories.json`
                const response = await fetch(catFile);
                const data = await response.json();
                context.commit('setCategories', data)
                return data;
            } catch (error) {
                console.error('Error loading categories:', error);
                context.commit('setCategories', [])
                return [];
            }
        },
        async loadCategoryData(context, category) {
            const data = context.getters.categoryData.find(data => {
                return data.category === category
            })

            if (data) {
                return data;
            }

            try {
                const catFile = `/images/prints/${category}/${category}.json`
                const response = await fetch(catFile);
                const data = await response.json();

                context.getters.categoryData.push({
                    category: category,
                    images: data.images,
                    description: data.description
                });
                return data;
            } catch (error) {
                console.error('Error loading category:', error);
                const nullData = {
                    category: category,
                    images: [],
                    description: 'No description available'
                }
                // push so we don't keep loading same error
                context.getters.categoryData.push(nullData);
                return nullData;
            }
        },
        async createImageRows(_, request) {
            const {images, rowHeight, maxWidth, gap} = request

            if (!images || images.length === 0) return [];

            const rows = [];
            let currentRow = [];
            let currentRowWidth = 0;

            // Helper to calculate scaled width based on desired height
            const getScaledWidth = (image, aspectRatio) => {
                const scaledWidth = rowHeight * aspectRatio;
                // console.log('scaledWidth: ', scaledWidth)
                return scaledWidth
            };

            // Process each image
            for (let i = 0; i < images.length; i++) {
                const image = images[i];

                const aspectRatio = image.width / image.height;
                let scaledWidth = getScaledWidth(image, aspectRatio);
                let scaledHeight = rowHeight;
                if (scaledWidth > maxWidth) {
                    scaledWidth = maxWidth;
                    scaledHeight = maxWidth / aspectRatio;
                }

                // Check if adding this image would exceed max width
                const gapSize = (currentRow.length - 1) * gap
                if (currentRowWidth + scaledWidth + gapSize > maxWidth && currentRow.length > 0) {
                    // now that we know how many images will fit in row, scale each image to fill row
                    const upscale = (maxWidth - gapSize) / currentRowWidth;
                    for (let j = 0; j < currentRow.length; j++) {
                        currentRow[j].displayWidth = currentRow[j].displayWidth * upscale;
                        currentRow[j].displayHeight = currentRow[j].displayHeight * upscale;
                    }

                    rows.push(currentRow);
                    currentRow = [];
                    currentRowWidth = 0;
                }

                // Add image to current row
                currentRow.push({
                    ...image,
                    displayWidth: scaledWidth,
                    displayHeight: scaledHeight
                });
                currentRowWidth += scaledWidth;

                // Handle last row or last image
                if (i === images.length - 1 && currentRow.length > 0) {
                    rows.push(currentRow);
                }
            }

            return rows;
        },
        async loadPrints(context) {
            if (context.getters["formats"]) {
                return;
            }

            try {
                const response = await fetch('/products/prints.json')
                if (response.status === 200) {
                    const formatGroups = await response.json()
                    if (formatGroups.length > 0) {
                        console.log('printFormats:', JSON.stringify(formatGroups, null, 2));
                        await context.commit("setFormats", formatGroups)
                        // await context.commit("loaded", true)
                    } else {
                        console.log('Prints is empty')
                        await context.commit("setFormats", [])
                    }
                } else {
                    // console.error('Error loading products:', response.status)
                    console.log('Prints not found');
                    await context.commit("setFormats", [])
                }
            } catch (error) {
                console.error('Error loading prints:', error)
                await context.commit("setFormats", [])
                // throw error
            }
        }
    }
}