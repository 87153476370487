<template>
  <div class="d-flex flex-column h-100">
    <header>
      <nav-bar />
    </header>
    <main class="flex-grow-0 p-0 m-0">
      <router-view/>
    </main>
    <footer class="footer mt-auto ">
      <footer-bar/>
    </footer>
  </div>
</template>

<script>
import NewNav from "@/components/nav/NewNav.vue";
import FooterBar from "@/components/nav/FooterBar.vue";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    NavBar: NewNav,
    FooterBar
  }
}
</script>

<style lang="scss">
$my-text: black;
$my-background: white;

$body-color: $my-text;
$body-emphasis-color: $my-text;
$body-tertiary-bg: $my-text;
$headings-color: $my-text;
$link-color: $my-text;
$link-hover-color: $my-text;
$border-color: $my-text;
$body-bg: $my-background;
$body-color-dark: $my-background;
$body-bg-dark: $my-text;
$theme-colors: (
  //  used by some links
  "primary": $body-color,
);

:root {
  --bs-border-opacity: 0.2;
  --bs-border-control-opacity: 0.8;
  --bs-external-color: #4CAF50;
  --bs-notice-gentle: #6495ED;
  --bs-notice-warning: #ff6b6b;
  --bs-link-selected: #0066cc;
}

// Import Bootstrap after variable definitions
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import 'bootstrap/scss/bootstrap';
@import '@/assets/global';
@import '@/assets/nav';
@import '@/assets/forms';
@import '@/assets/dialogs';

#app {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 300;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

main > .container {
  padding: 70px 15px 0;
}
</style>
