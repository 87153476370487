<template>
  <footer class="footer mt-3">
    <div class="container">
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 g-2 justify-content-center align-items-center">
        <!-- Footer items will go here -->

        <div class="col text-center">
          <a href="https://www.facebook.com/MossBeachLife"
             target="_blank"
             rel="noopener noreferrer"
             class="mx-2 p-0"
             title="Facebook">
            <i class="fab fa-facebook fa-lg"></i>
          </a>
          <a href="https://www.instagram.com/mossbeachlife/"
             target="_blank"
             rel="noopener noreferrer"
             class="mx-2 p-0"
             title="Instagram">
          <i class="fab fa-instagram fa-lg"></i>
          </a>
          <a href="https://www.j2clark.com/MBL"
             target="_blank"
             rel="noopener noreferrer"
             class="mx-2 p-0"
             title="j2clark.com">
          <i class="fa-solid fa-camera fa-lg"></i>
          </a>
        </div>
        <div class="col text-center">
          <a href="mailto:contact@mossbeachlife.com"
             target="_blank"
             rel="noopener noreferrer"
             class="mx-2 p-0 btn external-link"
             title="Contact Us"
          >
            contact@mossbeachlife.com
          </a>
        </div>
        <div class="col text-center">
          <p class="m-0">
            <i class="fa fa-copyright fa-lg" aria-hidden="true"></i>
            MossBeachLife.com
          </p>
        </div>
        <div v-if="enhanced" class="col text-center">
          <p class="m-0">{{ width }} x {{height}}</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  setup() {

    const route = useRoute();

    const width = ref(0);
    const height = ref(0);

    const enhanced = computed(() => {
      return route.query.mode === 'enhanced'
    })

    const updateDimensions = () => {
      height.value = window.innerHeight;
      width.value = window.innerWidth;
    };

    onMounted(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions)
    });

    return {
      width,
      height,
      enhanced
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-facebook {
  color: var(--bs-body-color);
}
.fa-facebook:hover {
  color: #3b5998;
}

.fa-instagram {
  color: var(--bs-body-color);
}
.fa-instagram:hover {
  color: #e1306c;
}

.fa-camera {
  color: var(--bs-body-color);
}

.fa-camera:hover {
  color: #3C9F40;
}
</style>
